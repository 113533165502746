import React from "react"
import { StaticQuery } from "gatsby"
import View from "./View"

const XIStateOfMind = () => (
  <StaticQuery
      query={graphql`
        query {
          gcms {
            xiStateOfMinds {
              documentTitle
              document {
                url
                fileName
              }
            }
          }
        }
      `}
      render={data => (
        <View title="Resources">
          <div className="w-full px-10 py-12">
            <div className="flex flex-wrap">
              <div className="w-full">
                <h1>XI State of Mind Resources</h1>
                <p>Take advantage of the resources and training available to you and let us know if there is anything else you need.</p>
                <ul>
                  {data.gcms.xiStateOfMinds.map(resource => {
                    const { documentTitle, document } = resource
                    return (
                      <li key={document.fileName}>
                        <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={document.url} target="_blank" rel="noopener noreferrer" download>
                        <p className="resourceCategory">{documentTitle}</p>
                        <div className="resourceLinkLabel">
                          {document.fileName}
                          <div>
                            <svg viewBox="0 0 58.3 61.7">
            									<path fill="#2a2a2a" className="st0" d="M12.3,29.9c-1-1-1-2.5,0-3.5s2.5-1,3.5,0l10.9,10.9V2.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v34.2l10.9-10.9
            										c1-1,2.5-1,3.5,0c1,1,1,2.5,0,3.5L30.6,44.7c-0.5,0.5-1.1,0.7-1.8,0.7s-1.3-0.2-1.8-0.7L12.3,29.9z M55.8,47.7
            										c-1.4,0-2.5,1.1-2.5,2.5v6.5H4.9v-6.5c0-1.4-1.1-2.5-2.5-2.5S0,48.8,0,50.2v9c0,1.4,1.1,2.5,2.5,2.5h53.4c1.4,0,2.5-1.1,2.5-2.5v-9
            										C58.3,48.8,57.2,47.7,55.8,47.7z"/>
            								</svg>
                          </div>
                        </div>
                        </a>
                      </li>
                    )
                  })}
                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'http://mcxu-employeetraining.edubrite.com/'} target="_blank">
                    <p className="resourceCategory">Employee</p>
                    <div className="resourceLinkLabel">
                      XI State of Mind Training & Certification
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://resources.inmoment.com/email-signature-generator/'} target="_blank">
                    <p className="resourceCategory">Email Signature</p>
                    <div className="resourceLinkLabel">
                      Email Signature Generator
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://drive.google.com/drive/folders/1TmzVyIhxhojZrzDqTa6zJEln'} target="_blank">
                    <p className="resourceCategory">Backgrounds & Wallpapers</p>
                    <div className="resourceLinkLabel">
                      Open in Google Drive
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://forms.gle/3DrEGqqEupvwYDr59'} target="_blank">
                    <p className="resourceCategory">Order Business Cards</p>
                    <div className="resourceLinkLabel">
                      Business Card Order Form
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://drive.google.com/drive/folders/1qB-Fi-2MD4pz1rhepivjY2LHBh55Y7u6'} target="_blank">
                    <p className="resourceCategory">Brand Guidelines, Logos, & Fonts</p>
                    <div className="resourceLinkLabel">
                      Open in Google Drive
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://drive.google.com/drive/folders/1xhwoL03p5AEWU69qUlugzjDcNuDFWZM_'} target="_blank">
                    <p className="resourceCategory">New Presentation Template</p>
                    <div className="resourceLinkLabel">
                      Open in Google Drive
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://drive.google.com/drive/folders/1KqSwSXZoMU5WDk1rhLK1UA5Jto0-BF5H'} target="_blank">
                    <p className="resourceCategory">Letterhead</p>
                    <div className="resourceLinkLabel">
                      Open in Google Drive
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                  <li>
                    <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={'https://drive.google.com/drive/folders/1Ehy8JalIP0a1-mNhDng6BaGBPEtcXQXh'} target="_blank">
                    <p className="resourceCategory">Social Media Posts</p>
                    <div className="resourceLinkLabel">
                      Open in Google Drive
                      <div>
                        <svg viewBox="0 0 20 20">
                          <path fill="#2a2a2a" d="M20 18.015V13.011C20 12.458 19.552 12.011 19 12.011C18.447 12.011 18 12.458 18 13.011V17.011C18 17.563 17.996 18 17.444 18H4.44C3.338 18 2 17.117 2 16.015V3.011C2 2.458 2.892 2 3.444 2H7.444C7.996 2 8.444 1.553 8.444 1C8.444 0.448 7.996 0 7.444 0H2.44C1.338 0 0 0.904 0 2.007V18.015C0 19.117 1.338 20 2.44 20H18.448C19.55 20 20 19.117 20 18.015Z" />
                          <path fill="#2a2a2a" d="M17.547 4.322L11.732 10.137C11.341 10.528 10.694 10.527 10.304 10.137C9.91302 9.746 9.89902 9.113 10.289 8.723L16.076 2.907C16.387 2.595 16.174 2.061 15.733 2.05L14.199 2.009C13.647 1.994 13 1.532 13 0.98V0.977C13 0.425 13.93 0 14.483 0H18C19.1 0 20 0.9 20 2V5.972C20 6.48715 19.8276 6.93611 19.3559 6.99376C18.8569 6.93978 18.4589 6.54178 18.444 6.029L18.406 4.663C18.393 4.222 17.859 4.01 17.547 4.322Z" />
                          <path fill="#2a2a2a" d="M19.3559 6.99376C19.3221 6.99788 19.2869 7 19.25 7H19.472C19.4328 7 19.394 6.99788 19.3559 6.99376Z" />
                        </svg>
                      </div>
                    </div>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </View>
      )}
    />
)

export default XIStateOfMind
