import React from "react"
// import PropTypes from "prop-types"

const View = ({ children }) => (
    <div className="w-full view">
      <div className="bg-white rounded content-card">
        {children}
      </div>
      <div className="w-1/3"></div>
    </div>
)


export default View
