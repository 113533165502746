import React from "react"
import { Link } from "@reach/router"
import View from "./View"
import WelcomeGraphic from "../../static/welcomeGraphic.png";
import townHallVideo from "../../static/videos/North America _ APAC Q2 Company All Hands.mp4";
const Home = () => {
  // <h2>Important Dates:</h2>
  // <ul>
  //   <li className="importantDate"><p><span>February 26</span> - Employee Briefings & Public Press Release</p></li>
  //   <li className="importantDate"><p><span>March 2</span> - Anticipated Deal Close</p></li>
  //   <li className="importantDate"><p><span>March 4-5</span> - Meet the Team</p></li>
  // </ul>
  return (
    <View>
      <div className="w-full px-10 py-12">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <h1>Better Together</h1>
            <p>Welcome! This site is designed to equip you with the resources you need as an employee of our combined company to own the moments that matter and live the XI State of Mind. You can find new messaging, training links, templates, logos, and resources available to you to ensure your personal success.</p>
            <p>It is our goal to help you become evangelists and advocates as our combined company challenges current market beliefs and takes the experience industry by storm.</p>
            <h2>Q2 2020 All Hands Meeting</h2>
            <video width="90%" controls>
              <source src={townHallVideo} type="video/mp4" />
              Your browser does not support this video.
            </video>
          </div>
          <div className="w-full lg:w-1/2">
            <img src={WelcomeGraphic} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4 px-10 py-6">

        <div className="w-full lg:w-1/4 px-4 py-4 flex-initial">
          <div className="card rounded-md overflow-hidden h-full flex flex-col justify-between">
            <div className="p-6">
              <h2>XI State of Mind</h2>
              <p>We are committed to helping every employee understand the XI State of Mind and our joint company as we embrace a new way of doing business. A way that will allow our clients to move beyond just managing and measuring experiences--to challenging the status quo and delivering Experience Improvement (XI). Visit often as we will post additional resources as they are developed.</p>
            </div>
            <Link className="btn-card" to="/app/xi-state-of-mind">
              <h5>View Resources</h5>
              <span>
                <svg className="arrow" width="64" height="12" viewBox="0 0 64 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M56.9697 0.46967C57.2626 0.176777 57.7374 0.176777 58.0303 0.46967L63.0303 5.46967C63.3232 5.76256 63.3232 6.23744 63.0303 6.53033L58.0303 11.5303C57.7374 11.8232 57.2626 11.8232 56.9697 11.5303C56.6768 11.2374 56.6768 10.7626 56.9697 10.4697L60.6893 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H60.6893L56.9697 1.53033C56.6768 1.23744 56.6768 0.762563 56.9697 0.46967Z"/>
                </svg>
              </span>
            </Link>
          </div>
        </div>

        <div className="w-full lg:w-1/4 px-4 py-4 flex-initial">
          <div className="card rounded-md overflow-hidden h-full flex flex-col justify-between">
            <div className="p-6">
              <h2>Voice of Employee</h2>
              <p>As an organization we know that the voice of the employee is our most valuable resource and we’re committed to ongoing two-way conversations and creating career opportunities. Throughout this transition, we will leverage our expertise in EX to listen, and respond to your ideas, questions and feedback in an effort to bring together our teams while building our new culture.</p>
            </div>
            <a className="btn-card" href="https://inmoment.com/websurvey/2/begin?gateway=InMomentVoE" target="_blank">
              <h5>Feedback</h5>
              <span>
                <svg className="arrow" width="64" height="12" viewBox="0 0 64 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M56.9697 0.46967C57.2626 0.176777 57.7374 0.176777 58.0303 0.46967L63.0303 5.46967C63.3232 5.76256 63.3232 6.23744 63.0303 6.53033L58.0303 11.5303C57.7374 11.8232 57.2626 11.8232 56.9697 11.5303C56.6768 11.2374 56.6768 10.7626 56.9697 10.4697L60.6893 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H60.6893L56.9697 1.53033C56.6768 1.23744 56.6768 0.762563 56.9697 0.46967Z"/>
                </svg>
              </span>
            </a>
          </div>
        </div>

        <div className="w-full lg:w-1/4 px-4 py-4 flex-initial">
          <div className="card rounded-md overflow-hidden h-full flex flex-col justify-between">
            <div className="p-6">
              <h2>Resources</h2>
              <p>As an organization we are committed to providing every employee with the resources and training to be prepared and comfortable with what these changes mean to you, and what they mean to our clients, prospects, and others.  We will continue to add resources as they become available.</p>
            </div>
            <Link className="btn-card" to="/app/resources">
              <h5>View Resources</h5>
              <span>
                <svg className="arrow" width="64" height="12" viewBox="0 0 64 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M56.9697 0.46967C57.2626 0.176777 57.7374 0.176777 58.0303 0.46967L63.0303 5.46967C63.3232 5.76256 63.3232 6.23744 63.0303 6.53033L58.0303 11.5303C57.7374 11.8232 57.2626 11.8232 56.9697 11.5303C56.6768 11.2374 56.6768 10.7626 56.9697 10.4697L60.6893 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H60.6893L56.9697 1.53033C56.6768 1.23744 56.6768 0.762563 56.9697 0.46967Z"/>
                </svg>
              </span>
            </Link>
          </div>
        </div>

        <div className="w-full lg:w-1/4 px-4 py-4 flex-initial">
          <div className="card rounded-md overflow-hidden h-full flex flex-col justify-between">
            <div className="p-6">
              <h2>Latest News</h2>
              <p>This is your one-stop location for the “Better Together” news available to you. Communications will continue to be sent to every employee via email and hosted here to ensure you have a real-time view of what is being said, and what is being done throughout this transition.</p>
            </div>
            <Link className="btn-card" to="/app/news">
              <h5>Learn More</h5>
              <span>
                <svg className="arrow" width="64" height="12" viewBox="0 0 64 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M56.9697 0.46967C57.2626 0.176777 57.7374 0.176777 58.0303 0.46967L63.0303 5.46967C63.3232 5.76256 63.3232 6.23744 63.0303 6.53033L58.0303 11.5303C57.7374 11.8232 57.2626 11.8232 56.9697 11.5303C56.6768 11.2374 56.6768 10.7626 56.9697 10.4697L60.6893 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H60.6893L56.9697 1.53033C56.6768 1.23744 56.6768 0.762563 56.9697 0.46967Z"/>
                </svg>
              </span>
            </Link>
          </div>
        </div>

      </div>

    </View>
  )
}

export default Home
