import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Home from "../components/Home"
import Resources from "../components/Resources"
import News from "../components/News"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Technology from "../components/Technology"
import xiStateOfMind from "../components/XIStateOfMind"
import Trainings from "../components/Trainings"
import CommunicationsArchive from "../components/CommunicationsArchive"
// <PrivateRoute path="/app/news/:slug" component={NewsSingle} />

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/home" component={Home} />
      <PrivateRoute path="/app/resources" component={Resources} />
      <PrivateRoute path="/app/xi-state-of-mind" component={xiStateOfMind} />
      <PrivateRoute path="/app/news" component={News} />
      <PrivateRoute path="/app/trainings" component={Trainings} />
      <PrivateRoute path="/app/resources/technology" component={Technology} />
      <PrivateRoute path="/app/resources/comms-archive" component={CommunicationsArchive} />
      <Login path="/app/login" />
    </Router>
  </Layout>
)

export default App
