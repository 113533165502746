import React from "react"
import { StaticQuery } from "gatsby"
import View from "./View"

const CommunicationsArchive = () => (
  <StaticQuery
      query={graphql`
        query {
          gcms {
            commsArchives {
              documentTitle
              document {
                url
                fileName
              }
            }
          }
        }
      `}
      render={data => (
        <View title="Communications Archive">
          <div className="w-full px-10 py-12">
            <div className="flex flex-wrap">
              <div className="w-full">
                <h1>Communications Archive</h1>
                <ul>
                  {data.gcms.commsArchives.map(post => {
                    const { documentTitle, document } = post
                    return (
                      <li key={document.fileName}>
                        <a className="resourceDownloadLink rounded-md shadow-xs px-6 py-2" href={document.url} target="_blank" rel="noopener noreferrer" download>
                        <p className="resourceCategory">{documentTitle}</p>
                        <div className="resourceLinkLabel">
                          {document.fileName}
                          <div>
                            <svg viewBox="0 0 58.3 61.7">
            									<path fill="#2a2a2a" className="st0" d="M12.3,29.9c-1-1-1-2.5,0-3.5s2.5-1,3.5,0l10.9,10.9V2.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v34.2l10.9-10.9
            										c1-1,2.5-1,3.5,0c1,1,1,2.5,0,3.5L30.6,44.7c-0.5,0.5-1.1,0.7-1.8,0.7s-1.3-0.2-1.8-0.7L12.3,29.9z M55.8,47.7
            										c-1.4,0-2.5,1.1-2.5,2.5v6.5H4.9v-6.5c0-1.4-1.1-2.5-2.5-2.5S0,48.8,0,50.2v9c0,1.4,1.1,2.5,2.5,2.5h53.4c1.4,0,2.5-1.1,2.5-2.5v-9
            										C58.3,48.8,57.2,47.7,55.8,47.7z"/>
            								</svg>
                          </div>
                        </div>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </View>
      )}
    />
)

export default CommunicationsArchive
