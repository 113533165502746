import React from "react"
import { StaticQuery } from "gatsby"
import View from "./View"

const News = () => (
  <StaticQuery
      query={graphql`
        query {
          gcms {
            newses {
              title
              description
              pdfFile {
                url
              }
            }
          }
        }
      `}
      render={data => (
        <View title="Resources">
          <div className="w-full px-10 py-12">
            <h1>News</h1>
            <p>Check back often and take advantage of the latest information available to you!</p>
            <div className="flex flex-wrap">
                {data.gcms.newses.map(news => {
                  const { title, description, pdfFile } = news
                  return (
                    <div className="w-full lg:w-1/3 px-2 py-4 flex-initial" key={title}>
                      <div className="card rounded-md overflow-hidden h-full flex flex-col justify-between">
                        <div className="p-6">
                          <h2>{title}</h2>
                          <p>{description}</p>
                        </div>
                        <a className="btn-card" href={pdfFile.url} target="_blank" rel="noopener noreferrer" download>
                          <h5>Read More</h5>
                          <span>
                            <svg className="arrow" width="64" height="12" viewBox="0 0 64 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M56.9697 0.46967C57.2626 0.176777 57.7374 0.176777 58.0303 0.46967L63.0303 5.46967C63.3232 5.76256 63.3232 6.23744 63.0303 6.53033L58.0303 11.5303C57.7374 11.8232 57.2626 11.8232 56.9697 11.5303C56.6768 11.2374 56.6768 10.7626 56.9697 10.4697L60.6893 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H60.6893L56.9697 1.53033C56.6768 1.23744 56.6768 0.762563 56.9697 0.46967Z"/>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </View>
      )}
    />
)

export default News
